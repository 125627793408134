[data-tooltip]:before {
  /* needed - do not touch */
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;

  /* customizable */
  cursor: auto;
  transition: all 0.15s ease;
  padding: 10px;
  color: rgb(255, 255, 255);
  border-radius: 10px;
}

[data-tooltip]:hover:before {
  /* needed - do not touch */
  opacity: 1;

  /* customizable */
  background: #848b8a;
  margin-bottom: -80px;
  margin-left: 20px;
}
