@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
  font-family: 'Certa Sans';
  src: local('Certa Sans Medium'), local('CertaSans-Medium'),
    url('./fonts/Certasansmedium.woff2') format('woff2'),
    url('./fonts/Certasansmedium.woff') format('woff'),
    url('./fonts/Certasansmedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

.ql-toolbar {
  background-color: #f8f9fa;
  border-radius: 8px 8px 0 0;
}

.quill {
  position: relative;
  border-radius: 20px;
}

.ql-container {
  border-radius: 0 0 8px 8px;
  background-color: white;
}
.ql-container .ql-editor {
  height: 266px;
}

.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
[data-testid='flowbite-tooltip-arrow'] {
  width: 0;
  height: 0;
  border-style: solid;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #e9e9e9;
  left: 6.5rem;
}

[data-tooltip]:before {
  /* needed - do not touch */
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;

  /* customizable */
  cursor: auto;
  transition: all 0.15s ease;
  padding: 10px;
  color: rgb(255, 255, 255);
  border-radius: 10px;
}

[data-tooltip]:hover:before {
  /* needed - do not touch */
  opacity: 1;

  /* customizable */
  background: #848b8a;
  margin-bottom: -80px;
  margin-left: 20px;
}
